// scss-docs-start caret-mixins
@mixin caret-down($width: $caret-width) {
  border-top: $width solid;
  border-right: $width solid transparent;
  border-bottom: 0;
  border-left: $width solid transparent;
}

@mixin caret-up($width: $caret-width) {
  border-top: 0;
  border-right: $width solid transparent;
  border-bottom: $width solid;
  border-left: $width solid transparent;
}

@mixin caret-end($width: $caret-width) {
  border-top: $width solid transparent;
  border-right: 0;
  border-bottom: $width solid transparent;
  border-left: $width solid;
}

@mixin caret-start($width: $caret-width) {
  border-top: $width solid transparent;
  border-right: $width solid;
  border-bottom: $width solid transparent;
}

@mixin caret(
  $direction: down,
  $width: $caret-width,
  $spacing: $caret-spacing,
  $vertical-align: $caret-vertical-align,
  $accordion: false // Boosted mod: $accordion param only there in Boosted
) {
  @if $enable-caret {
    &::after {
      display: inline-block;
      // Boosted mod: flexbox FTW!
      align-self: $vertical-align;
      @if ($accordion == false) {
        margin-left: $spacing;
      } @else {
        margin: 0 $spacing 0 auto;
      }
      // End mod
      content: "";
      @if $direction == down {
        @include caret-down($width);
        // stylelint-disable-next-line function-disallowed-list
        transform: translateY(calc(var(--#{$prefix}border-width) * .5)); // Boosted mod
      } @else if $direction == up {
        @include caret-up($width);
      } @else if $direction == end {
        @include caret-end($width);
      }
    }

    @if $direction == start {
      &::after {
        display: none;
      }

      &::before {
        // stylelint-disable-next-line declaration-no-important
        position: static !important; // Boosted mod: prevent custom split buttons to interfere
        display: inline-block;
        align-self: $vertical-align; // Boosted mod: flexbox FTW!
        margin-right: $spacing;
        // stylelint-disable-next-line declaration-no-important
        color: unset !important; // Boosted mod: prevent custom split buttons to interfere
        content: "";
        @include caret-start($width);
      }
    }

    &:empty::after {
      margin-left: 0;
    }
  }
}
// scss-docs-end caret-mixins
