// Boosted mod
.link-chevron {
  font-weight: $font-weight-bold;
  text-decoration: if($link-decoration == none, null, none);
  background-color: transparent;

  &::after {
    display: inline-block;
    width: $linked-chevron-icon-width;
    height: $linked-chevron-icon-height;
    margin-left: $linked-chevron-margin-left;
    vertical-align: middle;
    content: "";
    background-color: currentcolor;
    mask: var(--#{$prefix}chevron-icon) no-repeat;
    transform: $linked-chevron-transform;
  }

  &:hover {
    text-decoration: $link-decoration;
  }
}
// End mod
