// Pagination

// scss-docs-start pagination-mixin
@mixin pagination-size($padding-y, $padding-x, $font-size, $border-radius) {
  --#{$prefix}pagination-padding-x: #{$padding-x};
  --#{$prefix}pagination-padding-y: #{$padding-y};
  @include rfs($font-size, --#{$prefix}pagination-font-size);
  --#{$prefix}pagination-border-radius: #{$border-radius};
}
// scss-docs-end pagination-mixin

// scss-docs-start pagination-max-items-mixin
// Boosted mod
// Ensure we only display `n` items:
// - the first `n/2` items
// - the last `n/2` items
// - including the .active one (so maybe not the `n/2`th last item)
@mixin pagination-max-items($pagination-max-items) {
  display: none;

  &.active,
  &:nth-child(-n+#{$pagination-max-items * .5}),
  &:nth-last-child(-n+#{$pagination-max-items * .5}) {
    display: list-item;
  }
}
// scss-docs-end pagination-max-items-mixin
