// All-caps `RGBA()` function used because of this Sass bug: https://github.com/sass/node-sass/issues/2251
@each $color, $value in $theme-colors {
  .text-bg-#{$color} {
    // Boosted mod: Force colors to have a good rendering
    $text-bg-color: var(--#{$prefix}highlight-color);
    @if index(("primary", "warning", "light"), #{$color}) {
      $text-bg-color: $black;
    } @else if (#{$color} == "dark") {
      $text-bg-color: $white;
    }
    // End mod
    color: $text-bg-color if($enable-important-utilities, !important, null); // Boosted mod: instead of `color-contrast($value)`
    background-color: RGBA(var(--#{$prefix}#{$color}-rgb), var(--#{$prefix}bg-opacity, 1)) if($enable-important-utilities, !important, null);
  }
}
