//
// Labels
//

.form-label {
  margin-bottom: $form-label-margin-bottom;
  @include font-size($form-label-font-size);
  font-style: $form-label-font-style;
  font-weight: $form-label-font-weight;
  color: $form-label-color;
}

// Boosted mod: states for labels
.is-disabled,
[disabled] .form-label {
  color: $form-label-disabled-color;
}

.is-required::after {
  margin-left: $form-label-required-margin-left;
  color: $form-label-required-color;
  content: "*";
}

.form-helper {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  padding: 0;
  background: transparent;
  border: 0;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(#{$form-helper-size} - 5px); // stylelint-disable-line function-disallowed-list
    height: calc(#{$form-helper-size} - 5px); // stylelint-disable-line function-disallowed-list
    content: "";
    background-color: $form-helper-bg;
    border-radius: 50%; // stylelint-disable-line property-disallowed-list
    transform: translate(-50%, -50%);
  }

  &::after {
    display: block;
    width: $form-helper-size;
    height: $form-helper-size;
    content: "";
    background-color: $form-helper-color;
    mask: no-repeat center/100% $form-helper-icon;
  }

  .form-label + & {
    margin-bottom: $form-helper-label-margin-bottom;
    vertical-align: bottom;
  }
}
// End mod

// For use with horizontal and inline forms, when you need the label (or legend)
// text to align with the form controls.
.col-form-label {
  padding-top: add($input-padding-y, $input-border-width);
  padding-bottom: add($input-padding-y, $input-border-width);
  margin-bottom: 0; // Override the `<legend>` default
  @include font-size(inherit); // Override the `<legend>` default
  font-style: $form-label-font-style;
  font-weight: $form-label-font-weight;
  line-height: $input-line-height;
  color: $form-label-color;
}

.col-form-label-lg {
  padding-top: add($input-padding-y-lg, $input-border-width);
  padding-bottom: add($input-padding-y-lg, $input-border-width);
  @include font-size($input-font-size-lg);
}

.col-form-label-sm {
  padding-top: add($input-padding-y-sm, $input-border-width);
  padding-bottom: add($input-padding-y-sm, $input-border-width);
  @include font-size($input-font-size-sm);
}
